body {
    background-color: #eafcff;
    color: #002429;
}

.text-body-emphasis {
    font-family: 'Protest Riot', sans-serif;
    color: #002429 !important;
}

.question-card {
    display: flex;
    flex-direction: column;
    height: 350px;
    padding: 10px;
}

.question-card-header {
    flex: 1;
    display: flex;
    justify-content:right;
    opacity: 0.5;
    align-content: center;
}


.choice-indicator {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DDFBFF;
    color: #002429;
    margin-right: 10px; /* Adjusted for spacing */
    font-size: medium;
    font-weight: 800;
    line-height: 32px;

}

.question-card-header .question-age {
    padding-right:10px;
    font-size: medium;
    color:#DDFBFF;
    font-weight: bold;
}

.question-card .question-title {
    font-size: 1.3rem;
    font-weight:700;
    margin-bottom: 20px;
    min-height:50px;
}

.choice-text {
    line-height: 32px;
    font-weight: 600;
}

.choice-correct {
    background-color: #71C874;
}

.text-correct {
    color: #71C874;
}

.choice-incorrect {
    background-color: #FF7792;
}

.question-card .text-incorrect {
    color: #FF7792;
}

.question-nature {
    background-color: #69E9B1;
    color: #002429;
}

.question-science {
    background-color: #513C31;
    color: #DDFBFF;
}

.question-technology {
    background-color: #4600D1;
    color: #DDFBFF;
}

.question-everyDayLife {
    background-color: #FF9B65;
    color: #002429;
}

.question-artAndCulture {
    background-color: #FFB6D0;
    color: #002429;
}

.question-mathematics {
    background-color: #002429;
    color: #DDFBFF;
}

.question-sportAndPhysicalEducation {
    background-color: #FFE658;
    color: #002429;
}

.filter-container {
    border: 0;
    color: #002429;
}

.filter-container-title{
    padding-bottom: 30px;
    font-size: 1.3rem;
    font-weight:700;
}

.filter-label{
    font-size: 1.1rem;
    font-weight:600;
    padding-bottom: 10px;
}

.filter-age-container{
    padding-bottom: 10px;
}

.filter-categories-container{
    padding-bottom: 30px;
}

.custom-button {
    font-weight: bold;
    border-width: 2px !important;
    background-color: transparent !important;
    color: #002429 !important;
    border-color: #002429 !important;
}

.custom-button:hover {
    background-color: #002429 !important;
    color: #DDFBFF !important;
    border-color: #002429 !important;
}

.custom-toggle-button {
    font-weight: bold;
    border-width: 1.5px !important;
    background-color: transparent !important;
    color: #002429 !important;
    border-color: #002429 !important;
}

.custom-toggle-button-checked {
    font-weight: bold;
    border-width: 1.5px !important;
    background-color: #002429 !important;
    color: #DDFBFF !important;
    border-color: #002429 !important;
}

.custom-navbar {
    background-color: transparent !important;
}